import React, { memo, useState } from "react";
import {
  Space,
  Popconfirm,
  Modal,
  List,
  Typography,
  Form,
  Radio,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import "./completed.scss";
import BasicButtonPrimary from "components/BasicButtonPrimary";

const Completed = ({
  status,
  onClick,
  isShowAction = false,
  listFailReason = [],
  note = "",
}) => {
  const { Title, Text } = Typography;
  const { t: translation } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const LIST_STATUS = {
    Completed: translation("accreditation.statusText.completed"),
    Failed: translation("accreditation.statusText.failed"),
    Canceled: translation("accreditation.statusText.canceled"),
    New: "Mới",
  };
  const handleConfirmFailed = (values) => {
    onClick("Failed", values?.reasonFailed?.stationOperateResultId);
    setIsShowModal(false);
  };
  const showStatus = (status) => {
    switch (status) {
      case "Failed":
        return note ? note : translation("accreditation.statusText.failed");
      default:
        return LIST_STATUS[status];
    }
  };

  return (
    <div className={`completed__text--${status ? status : ""}`}>
      {!isShowAction ? (
        showStatus(status)
      ) : (
        <Space size={16}>
          <Popconfirm
            title={translation("accreditation.popConfirm", {
              status: translation(
                "accreditation.statusText.canceled"
              ).toLowerCase(),
            })}
            onConfirm={() => onClick("Canceled")}
            okText={translation("accreditation.yes")}
            cancelText={translation("accreditation.no")}
          >
            <div className="completed__icon">
              <DeleteOutlined style={{ color: "#8C8C8C" }} />
            </div>
          </Popconfirm>
          <div className="completed__icon" onClick={() => setIsShowModal(true)}>
            <CloseCircleOutlined style={{ color: "#F5222D" }} />
          </div>
          <Popconfirm
            title={translation("accreditation.popConfirm", {
              status: translation(
                "accreditation.statusText.completed"
              ).toLowerCase(),
            })}
            onConfirm={() => onClick("Completed")}
            okText={translation("accreditation.yes")}
            cancelText={translation("accreditation.no")}
          >
            <div className="completed__icon">
              <CheckCircleOutlined style={{ color: "#389E0D" }} />
            </div>
          </Popconfirm>
        </Space>
      )}
      <Modal
        visible={isShowModal}
        onCancel={() => setIsShowModal(false)}
        footer={null}
        centered
      >
        <div className="text-center p-4">
          <Title level={4}>
            {translation("settingAccreditation.resultRequired")}
          </Title>
          <Form
            name="confirmReasonFailed"
            autoComplete="off"
            onFinish={handleConfirmFailed}
            size="small"
            colon={false}
            labelAlign="right"
          >
            <Form.Item
              className="w-100"
              name="reasonFailed"
              rules={[
                {
                  required: true,
                  message: translation("settingAccreditation.resultRequired"),
                },
              ]}
            >
              <Radio.Group className="w-100">
                <List
                  bordered
                  className="mt-3 list-group w-100"
                  dataSource={listFailReason}
                  renderItem={(item) => (
                    <List.Item className="list-group-item list-group-item-action list-group-item-light border rounded w-100">
                      <Radio value={item || ""}>
                        <Text>{item?.stationOperateResultName || ""}</Text>
                      </Radio>
                    </List.Item>
                  )}
                />
              </Radio.Group>
            </Form.Item>
            <div className="mt-3 text-center">
              <BasicButtonPrimary text={translation("accreditation.save")} />
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default memo(Completed);
